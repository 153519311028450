

.small-font {
	font-size: .8rem;
}

/* GLOBAL */
body {
	background-color: #4B83C3;
	font-family: 'Montserrat-Medium', sans-serif !important;
	font-weight: 500; /*Medium*/
}
@font-face {
	font-family: "Montserrat-Medium";
	src: local("Montserrat-Medium"),
	 url("../../fonts/Montserrat-Medium.ttf") format("truetype");
}
.bg-body {
	background-color: #DBE5EC!important;
}
h1, .pageTitleUp {
	margin-top: -3rem!important;
	margin-bottom: 2.5rem;
}
button {
	border-radius: 12px!important;
}
.form-check-input:checked {
    background-color: #8A8DBC;
    border-color: #8A8DBC;
}

/* Custom styles global */
.text-start {
	margin-bottom: 20px;
}

._pe-05 {
    padding-right: 0.2rem!important;
}

.isLink {
    cursor: pointer;
}
.linkNoDecoration {
	text-decoration: none;
	color: black;
}
.userActionLink:nth-child(2n) {
    margin-left: 8px;
	width: 20px;
}

.underline {
	text-decoration: underline;
}

.bgwhite {
	background-color: white;
}

.bggrey {
	background-color: #959797;
	color: white;
}
.bordergrey {
	border: #959797 solid 1px;
}

.bgdarkgrey {
	background-color: #3B3F41;
	color: white;
}

.bgorange, .btnorange {
	background-color: #F5A038;
	color: white;
}
.borderorange {
	border: #F5A038 solid 1px;
}

.bgblue, .btnblue {
	background-color: #4B83C3;
	color: white;
}
.borderblue {
	border: #4B83C3 solid 1px;
}

.borderblack {
	border: solid 1px;
}

.begrey{
	border-right: #959797 solid 1px;
}

.bewhite{
	border-right: white solid 1px;
}
.bbwhite{
	border-bottom: white solid 1px;
}
.bbdarkgrey{
	border-bottom: #3B3F41 solid 1px;
}
.requiredField{
	color: #F5A038;
}

/* Modals styles */
.modal-title {
	color: #838383;
}
.modal-body h5, .modal-body h2 {
	color: black;
	font-weight: 600;
}
.modal-body p.subtitle {
	color: #838383;
}
.modal-body label {
	font-weight: 600;
}

/* Sidebar styles */
.sidebar, .sidebar-brand, .sidebar-footer {
	background-color: #4B83C3;
}
.sidebar-nav .nav-item a {
	color: white!important;
}
.sidebar-nav .nav-link, .sidebar-footer {
	margin: 10px;
    padding: 10px 20px;
    border-radius: 10px;
}
.sidebar-nav .nav-link:hover {
	background-color: #2D69A5;
}
.sidebar-nav  .nav-link.active {
	background-color: #16528E;
}
.sidebar-brand-full {
	max-width: 200px;
	margin-left: -15px;
}
.sidebar-footer:hover {
	background-color: #2D69A5;
}

/* Sidebar functionality */

.wrapper {
    width:100%;
    will-change:auto;
    transition:padding .15s
}

html:not([dir=rtl]) .wrapper {
	padding-left: var(--cui-sidebar-occupy-start, 0)
}

*[dir=rtl] .wrapper {
	padding-right: var(--cui-sidebar-occupy-start, 0)
}

@media(prefers-reduced-motion:reduce) {
	.wrapper {
		transition: none
	}
}

.ml-3 {
	margin-left: 5px;
}

html:not([dir=rtl]) .sidebar:not(.sidebar-end) {
    margin-left: 0;
}

@media(min-width:768px) {
	html:not([dir=rtl]) .sidebar.hide:not(.sidebar-end) {
		margin-left: calc(-1 * var(--cui-sidebar-width))
	}
	*[dir=rtl] .sidebar.hide:not(.sidebar-end) {
		margin-right: calc(-1 * var(--cui-sidebar-width))
	}
	.sidebar.hide:not(.sidebar-end)~* {
		--cui-sidebar-occupy-start: 0
	}
	html:not([dir=rtl]) .sidebar.hide.sidebar-end {
		margin-right: calc(-1 * var(--cui-sidebar-width))
	}
	*[dir=rtl] .sidebar.hide.sidebar-end {
		margin-left: calc(-1 * var(--cui-sidebar-width))
	}
	.sidebar.hide.sidebar-end~* {
		--cui-sidebar-occupy-end: 0
	}
}

/* Header styles */
.navitemRemainingLeads {
	position: relative;
	min-width: 100%;
}
.remainingLeads {
    font-weight: 600;
}
.progress {
	background-color: #D3D1E4;
}
.progress-bar {
	background-color: #514695;
}
.navitemProgressbar {
	position: relative;
	min-width: 60%;
}
.navitemProgressbar .progress {
	position: relative;
    top: 30%;
}
.navitemBuyLeads {
	position: relative;
	min-width: 100%!important;
}
.navitemBuyLeads div {
	position: relative;
	cursor: pointer;
	color: #F5A038;
}

.alertNoLeads {
	padding: 0.6rem 1rem !important;
	margin-bottom: 0!important;
}

.navitemWelcome {
	position: relative;
}
.navitemWelcome p {
	position: relative;
    top: 20%;
}

.navitemLogout {
	position: relative;
}
.navitemLogout img {
	position: relative;
    top: 30%;
}
.today-subheader {
	margin-bottom: 2px;
}

@media(max-width:1280px) {
	.navitemRemainingLeads {
		min-width: 60%;
	}
}

@media(max-width:1100px) {
	.navitemRemainingLeads {
		min-width: 50%;
	}
	.navitemProgressbar {
		min-width: 30%;
	}
}

@media(max-width:952px) {
	.container-fluid {
		display: block!important;
	}
	.navitemRemainingLeads {
		min-width: 27%;
	}
	.navitemProgressbar {
		min-width: 25%;
	}
}